export const userStatusDoesNotExistInDb = "DoesNotExistInDb";
export const userStatusActive = "Active";
export const userStatusWaitingForBrokerApproval = "WaitingForBrokerApproval";
export const basicPermissions = [
  {
    appName: "nexuswebcollectbrokerinfo",
    appUrl: "/collectbrokerinfo/",
    appId: "14",
    icon: process.env.REACT_APP_STORAGE_LINK + "icons/icon-admin.png",
    title: "Get More Apps ...",
  },
  {
    appName: "nexuswebresearch",
    appUrl: "/research/",
    appId: "7",
    icon: process.env.REACT_APP_STORAGE_LINK + "icons/icon-research.png",
    title: "Research",
  },
  {
    appName: "nexuswebsecuredmessagecenter",
    appUrl: "/securedmessagecenter/",
    appId: "9",
    icon: process.env.REACT_APP_STORAGE_LINK + "icons/icon-secure-message.png",
    title: "Secured Message Center",
  },
];
