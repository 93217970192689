import React, { useState } from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import DocumentIcon from "@material-ui/icons/Description";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import IconButton from "@material-ui/core/IconButton";
import BoxTheme from "../box-theme";

export default function WidgetFavoriteResearches(props) {
  const [range, setRange] = useState([0, 4]);

  function iconGenerator(item) {
    if (item === "audio") return <AudiotrackIcon />;
    else if (item === "document") return <DocumentIcon />;
    else return <HelpOutlineIcon />;
  }

  function PageChange(Direction) {
    let arr =
      Direction === "Prev"
        ? [range[0] - 5, range[1] - 5]
        : [range[0] + 5, range[1] + 5];

    setRange(arr);
  }

  function RangeShow() {
    let list = [];

    let lastItem = props.items[range[1]] ? range[1] : props.items.length - 1;

    for (let i = range[0]; i <= lastItem; i++) {
      list.push(
        <li className="WidgetLI" key={i}>
          <div className="WidgetIcon">{iconGenerator(props.items[i].type)}</div>

          <div className="WidgetText">
            <a
              href={
                window.location.origin +
                "/research/ResearchID/" +
                props.items[i].id
              }
              target="_blank"
              title={props.items[i].title}
              alt={props.items[i].title} rel="noreferrer"
            >
              {props.items[i].title}
            </a>

            <IconButton
              className="WidgetResearchArrow"
              href={
                window.location.origin +
                "/research/ResearchID/" +
                props.items[i].id
              }
              target="_blank"
            >
              <ArrowForwardIcon className="WidgetArrow" />
            </IconButton>
          </div>
        </li>
      );
    }

    return list;
  }

  return (
    <BoxTheme
      title="My Favorite Research"
      Paging={(Direction) => PageChange(Direction)}
      PagingPrev={props.items[range[0] - 1]}
      PagingNext={props.items[range[1] + 1]}
      component={
        <div className="WidgetBox">
          <ul className="WidgetList">{RangeShow()}</ul>
        </div>
      }
    />
  );
}
