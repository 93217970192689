import React, { useState } from "react";
import axios from "axios";

function ApiTest(props) {
  const [type, setType] = useState("get");
  const [apiRoute, setApiRoute] = useState("");
  const [params, setParams] = useState("");
  const [result, setResult] = useState("");
  async function fetchFunc() {
    let token = await props.acquireTokenPossibly();
    let data = await FetchData(token);
    setResult(data);
  }

  function FetchData(token) {
    let fParams;
    const headers = {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };
    if (params.length > 0) fParams = JSON.parse(params);
    if (type === "get")
      return axios.get(apiRoute, { headers, fParams, timeout: 120000 });
    else if (type === "delete")
      return axios.delete(apiRoute, { headers, fParams });
    else if (type === "post") return axios.post(apiRoute, fParams, { headers });
    else if (type === "put") return axios.put(apiRoute, fParams, { headers });
    else return "error";
  }

  return (
    <div style={{ margin: "20px" }}>
      <label style={{ width: "100%" }}>Route </label>
      <input
        style={{ width: "100%" }}
        type="text"
        id="route"
        name="route"
        value={apiRoute}
        onChange={(i) => setApiRoute(i.target.value)}
      />
      <br />
      <br />
      <label style={{ width: "100%" }}>Params </label>
      <textarea
        style={{ width: "100%", height: "200px" }}
        type="text"
        id="params"
        name="params"
        value={params}
        onChange={(i) => setParams(i.target.value)}
      />
      <br />
      <br />
      <label style={{ width: "100%" }}>Type </label>
      <select
        name="type"
        id="type"
        value={type}
        onChange={(i) => setType(i.target.value)}
      >
        <option value="get">GET</option>
        <option value="post">POST</option>
        <option value="put">PUT</option>
        <option value="delete">DELETE</option>
      </select>
      <br />
      <br />
      <button onClick={() => fetchFunc()}>Fetch</button>
      <br />
      <br />
      {result.length > 0 ? (
        <>
          <label>Result</label>
          <div>{result}</div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default ApiTest;
