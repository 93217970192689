import { applyMiddleware, compose, createStore } from "redux";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import rootReducer from "./reducers";

const initialState = {
  User: {
    Settings: {
      Local: { Symbol: { Code: null, Name: null } },
      Global: {
        Theme: "light",
        FavoriteAccounts: [],
      },
    },
    Sites: [],
    Barchart: {
      IsFocused: false,
      CurrentItem: "",
    },
  },
};
export default function configureStore() {
  // Include reduxImmutableStateInvariant only for DEV
  if (+process.env.REACT_APP_IS_PROD)
    return createStore(rootReducer, initialState, applyMiddleware());
  // No Redux or reduxImmutableStateInvariant
  else {
    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      }) || compose; // add support redux devtools
    return createStore(
      rootReducer,
      initialState,
      composeEnhancers(applyMiddleware(reduxImmutableStateInvariant()))
    );
  }
}
