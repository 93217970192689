import React from "react";
import IconButton from "@material-ui/core/IconButton";
import SimpleBadge from "./SimpleBadge";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

const theme = {
  overrides: {
    MuiIconButton: {
      label: {
        height: "32px",
        width: "32px",
      },
    },
  },
};

export default function MessageCenter(props) {
  return (
    <MuiThemeProvider theme={createTheme(theme)}>
      <IconButton
        style={{ backgroundColor: "transparent" }}
        aria-label="SecuredMessageCenter"
        onClick={(e) => window.open("/securedmessagecenter/")}
      >
        <SimpleBadge UnreadMessageCount={props.UnreadMessageCount} />
      </IconButton>
    </MuiThemeProvider>
  );
}
