import * as types from "../../statics/actionConstants";

export const postUserSettingsLocalSymbol = (Symbol) => ({
  type: types.USER_SETTINGS_LOCAL_SYMBOL,
  Symbol,
});
export const postUserSettingsLocal = (Local) => ({
  type: types.USER_SETTINGS_LOCAL,
  Local,
});
export const postUserSettingsGlobal = (Global) => ({
  type: types.USER_SETTINGS_GLOBAL,
  Global,
});
export const postUserSites = (Sites) => ({
  type: types.USER_SITES,
  Sites,
});
export const postUserBarchartCurrentItem = (CurrentItem) => ({
  type: types.USER_BARCHART_CURRENT_ITEM,
  CurrentItem,
});
export const postUserBarchartIsFocused = (IsFocused) => ({
  type: types.USER_BARCHART_IS_FOCUSED,
  IsFocused,
});
