//Props
//name - name of user to display on the right side
//menuUserSettings - component that will go in the far right side of the screen
//componentOne - component immediately to the right of the logo
//componentTwo - component immediately to the right of componentOne
//componentThree - component immediately to the right of componentTwo
//theme - the theme of the page
//sites - the allowed sites used for the menu app drawer

// Essentials
import React, { Component } from "react";
import MenuAppDrawer from "./MenuAppDrawer";
import Logo from "./Logo";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

const theme = {
  overrides: {
    MuiPaper: {
      elevation4: {
        boxShadow: "none",
      },
    },
  },
};

class Topbar extends Component {
  render() {
    return (
      <MuiThemeProvider theme={createTheme(theme)}>
        <AppBar
          position="fixed"
          color="default"
          style={{
            height: "90px",
            width: "100%",
            zIndex: 99, //9999 makes it show on top of modal
            backgroundColor: "#ffffff",
            border: "solid 1px #e9e9e9",
          }}
        >
          <Toolbar style={{ padding: 0, height: "100%" }}>
            <Logo userType={this.props.userType} />
            {typeof this.props.componentone === "function" &&
              this.props.componentone()}
            {typeof this.props.componenttwo === "function" &&
              this.props.componenttwo()}
            {typeof this.props.componentthree === "function" &&
              this.props.componentthree()}
            <div
              id="AppName"
              style={{
                width: "100%",
                position: "absolute",
                bottom: 0,
              }}
            >
              <h1
                style={{
                  textAlign: "center",
                  fontFamily: "'Libre Baskerville', serif",
                  fontSize:
                    "calc(12px + (31 - 12) * ((100vw - 400px) / (1600 - 400)))",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  color: "#50606e",
                  textTransform: "uppercase",
                  letterSpacing: "2px",
                  margin: "40px 0 0 0",
                }}
              >
                {process.env.REACT_APP_NAME_FRIENDLY}
              </h1>
            </div>
            <div
              style={{
                position: "absolute",
                right: "0px",
                top: "5px",
                display: "inherit",
              }}
            >
              {/* Related Accounts */}
              {this.props.RelatedAccounts && this.props.RelatedAccounts}

              {/* Apps */}
              <MenuAppDrawer
                sites={this.props.sites}
                AppIcons={this.props.AppIcons}
              />

              {/* Message Center */}
              {this.props.MessageCenter && this.props.MessageCenter}

              {/* UserSettings */}
              {this.props.MenuUserSettings && this.props.MenuUserSettings}
            </div>
          </Toolbar>
        </AppBar>
      </MuiThemeProvider>
    );
  }
}

export default Topbar;
