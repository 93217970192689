import React, { useEffect, useState } from "react";
import { useIsAuthenticated, useMsal, MsalProvider } from "@azure/msal-react";
import {
  PublicClientApplication,
  InteractionStatus,
  InteractionType,
  EventType,
} from "@azure/msal-browser";
import {
  loginRequest,
  protectedResources,
  msalConfig,
  b2cPolicies,
} from "./auth-config";

const instance = new PublicClientApplication(msalConfig);
let accounts = instance.getAllAccounts();
let params = {
  account: accounts[0],
  scopes: protectedResources.scopes,
};

const AuthWrapper = (Main) => () => {
  return (
    <MsalProvider instance={instance}>
      <AuthProvider>
        <Main />
      </AuthProvider>
    </MsalProvider>
  );
};

export default AuthWrapper;

const AuthProvider = ({ children }) => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [ready, setReady] = useState(null);

  // Once Component mount check if user authenticated
  useEffect(() => {
    accounts = instance.getAllAccounts();
    params = {
      account: accounts[0],
      scopes: protectedResources.scopes,
    };
    // password reset
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (
          event.error &&
          event.error.errorMessage.indexOf("AADB2C90118") > -1
        ) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
          } else if (event.interactionType === InteractionType.Popup) {
            instance
              .loginPopup(b2cPolicies.authorities.forgotPassword)
              .catch((e) => {
                return;
              });
          }
        }
      }
    });

    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance.loginRedirect(loginRequest);
    } else if (typeof accounts[0] !== "undefined") {
      setReady(true);
    }
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [inProgress, isAuthenticated, ready]);

  // If authenticated show content
  if (ready) return <>{children}</>;
  else return "";
};

// get how many minutes session left
export const b2cGetSessionTimeLeft = () => {
  let localTokenExpiration = localStorage.getItem("nexusTokenExpiration");
  if (localTokenExpiration) {
    return Math.round(
      (Math.round(new Date(localTokenExpiration) / 1000) - Math.round(Date.now() / 1000)) /
      60);
  } else {
    return 0;
  }


};
// logout
export const b2cLogout = () => {
  localStorage.removeItem("nexusTokenExpiration");
  localStorage.removeItem("nexusToken");
  return instance.logoutRedirect();
};

// not exported function, set to be used locally
export const fetchToken = async (forceRefresh = false) => {
  const localTokenExpiration = localStorage.getItem("nexusTokenExpiration");
  const localToken = localStorage.getItem("nexusToken");

  if (localTokenExpiration && b2cGetSessionTimeLeft() < 1) {
    return b2cLogout();
  };

  //use local token, if there is more than 15 mins left on token expiration
  if (localToken && b2cGetSessionTimeLeft() > 15) {
    return localToken;
  }

  //updating token with new one. 
  params.forceRefresh = forceRefresh;
  let token;
  try {
    token = await instance
      .acquireTokenSilent(params)
      .then((response) => response)
      .catch(() =>
        instance
          .acquireTokenRedirect(params)
          .then((response) => response)
      );
    localStorage.setItem("nexusTokenExpiration", token.expiresOn);
    localStorage.setItem("nexusToken", token.accessToken);
    return token.accessToken;
  } catch (err) {
    console.log("error", err);
    instance.loginRedirect(loginRequest);
  }
};

// auth functions
// do not logout, just show logged out page
export const b2cLogoutSoft = () => {
  localStorage.removeItem("nexusTokenExpiration");
  let origin = window.location.origin;
  const appDomain = `${origin}/logout/${origin}/${process.env.REACT_APP_PATH}`;
  return window.location.assign(appDomain);
};
// UserType
export const b2cUserType = () => {
  return accounts[0].idTokenClaims.extension_CustomerOfCountry;
};

// getAccountDetails
export const b2cGetAccount = () => {
  return accounts[0];
};

export const b2cResetPassword = () => {
  return instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
}
