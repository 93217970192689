import React from "react";
import ADMIS_LOGO from "./img/ADMIS-Logo.jpg";

export default function Logo(props) {
  return (
    <a href="/" style={{ zIndex: 99999 }}>
      <img
        src={ADMIS_LOGO}
        style={{ height: "50px", margin: "12px 25px 0px 10px" }}
        alt={"ADMIS Logo"}
      />
    </a>
  );
}
