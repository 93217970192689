let Domain = +process.env.REACT_APP_IS_PROD
  ? window.location.origin
  : "https://devnexus.admis.com";
export const grapthApiRoot = process.env.REACT_APP_AZURE_API_GRAPH_B2C;
export const formsManagerRoot = process.env.REACT_APP_FORMS_MANAGER;

export const userPermissionsRoot = process.env.REACT_APP_AZURE_PERMISSION;
export class Constants {
  //  Pages
  static pages = [
    {
      name: "Users",
      link: "/Users",
    },
    {
      name: "User Actions",
      link: "/UserActions",
    },
    {
      name: "Group Actions",
      link: "/GroupUserActions",
    },
  ];
}

export const userSettingsRoot = Domain + "/api/usersettings/api/";

export const domain = Domain;
