import { createTheme } from "@material-ui/core/styles";

export default function index(mode) {
  return createTheme({
    palette: {
      type: mode,
      primary: {
        light: "#FF0000",
        main: "#3f50b5",
        dark: "#002884",
        contrastText: "#002884",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
    typography: {
      useNextVariants: true,
    },
  });
}
