import React, { useState } from "react";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { Link } from "@material-ui/core";
import { PrettyPrice } from "../sharedComponents/helpers";
import BoxTheme from "../box-theme";

export default function WidgetFavoriteAccounts(props) {
  const [range, setRange] = useState([0, 4]);
  function GetIcon(LV) {
    if (LV === 0) {
      return (
        <>
          <div>0</div>
        </>
      );
    } else if (LV > 0) {
      return (
        <>
          <div className="Positive">
            <ArrowUpwardIcon className="WidgetArrow" />
            {PrettyPrice(LV, 2)}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="Negative">
            <ArrowDownwardIcon className="WidgetArrow" />
            {PrettyPrice(LV, 2)}
          </div>
        </>
      );
    }
  }

  function PageChange(Direction) {
    let arr =
      Direction === "Prev"
        ? [range[0] - 5, range[1] - 5]
        : [range[0] + 5, range[1] + 5];

    setRange(arr);
  }

  function RangeShow() {
    let list = [];
    let lastItem = props.items[range[1]] ? range[1] : props.items.length - 1;
    for (let i = range[0]; i <= lastItem; i++) {
      list.push(
        <li className="WidgetLI" key={i}>
          <Link
            className="WidgetAccountNumber"
            href={"/accountsnapshots/AccountNumber=" + props.items[i].Account}
          >
            {props.items[i].Account}
          </Link>
          <div className="WidgetPercentage">
            {GetIcon(props.items[i].ChangeInLV)}
          </div>
        </li>
      );
    }
    return list;
  }
  return (
    <BoxTheme
      title="My Favorite Accounts"
      Paging={(Direction) => PageChange(Direction)}
      PagingPrev={props.items[range[0] - 1]}
      PagingNext={props.items[range[1] + 1]}
      component={
        <div className="WidgetBox">
          <ul className="WidgetList">{RangeShow()}</ul>
        </div>
      }
    />
  );
}
