import { ApiCaller, ApiCallerNoAuth } from "./api-caller";
import { Component } from "react";
import * as Constants from "./constants";
import { APICall } from "./user-response";
import { fetchToken, b2cGetAccount } from "../auth-wrapper";

class ApiClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: b2cGetAccount()?.username,
    };
  }

  // API Callers
  CallApi = async (type, route, params) => {
    if (!this.state.email) {
      const userEmail = b2cGetAccount()?.username;
      this.setState({ email: userEmail });
    }
    let token = await fetchToken();
    return ApiCaller(type, token, route, params)
      .then((res) => {
        if (res.status === 200) return res.data;
      })
      .catch((e) => {
        if (e.code === "ECONNABORTED")
          return this.props.enqueueSnackbar(`${e} Please refresh the page.`, {
            variant: "error",
          });
        else return this.OnError(e);
      });
  };
  OnError = (e) => {
    let err = "";
    if (e.data) {
      if (e.data.error && e.data.error.message) {
        err = e.data.error.message;
      } else if (e.data.message) {
        err = e.data.message;
      } else if (e.data) {
        err = e.data;
      }
    } else if (e) {
      err = e;
    }
    //with snachbar wasnt passed as a prop. This is a temporary fix
    // this.props.enqueueSnackbar(`${APICall.Error} ${err}`, {
    //   variant: "error",
    // });
    console.log("API Error: ", err);
  };
  CallApiNoAuth = async (route) => {
    return ApiCallerNoAuth(route)
      .then((res) => {
        if (res.status === 200) return res.data;
      })
      .catch((e) => this.OnError(e.response));
  };
  CallApiGetAll = async (type, route, params) => {
    let token = await fetchToken();
    return ApiCaller(type, token, route, params)
      .then((res) => {
        if (res.status === 200) return res;
      })
      .catch((e) => this.OnError(e.response));
  };
  CallApiSkipErrorHandling = async (type, route, params) => {
    let token = await fetchToken();
    return ApiCaller(type, token, route, params).then((res) => {
      if (res.status === 200 || res.status === 201) return res.data;
    });
  };

  // Delete
  DeleteUserSettings = async (appName) => {
    let app = appName === "global" ? appName : process.env.REACT_APP_ID;
    let apiRoute = `${Constants.userSettingsRoot}UserSetting/${app}/${this.state.email}`;
    return this.CallApi("delete", apiRoute);
  };

  // Get
  GetUnreadMessages = async () => {
    let apiRoute = Constants.messagesRoot + "UnreadCount";
    return this.CallApi("get", apiRoute);
  };
  GetRelatedSubAccount = async (Office, Account) => {
    let apiRoute =
      Constants.apiAccountSnapshotRoot + "Positions/GetRelatedSubAccount";
    let params = { Office, Account };
    return this.CallApi("get", apiRoute, params);
  };
  GetUserPermissions = async () => {
    let apiRoute = Constants.userPermissionsRoot + "GetAllowedApps";
    let params = { userId: this.state.email };
    return this.CallApi("get", apiRoute, params);
  };
  GetUserPermissionsDetailed = async () => {
    let apiRoute = Constants.userPermissionsRoot + "getapplicationpermissions";
    let params = { application: process.env.REACT_APP_ID };
    return this.CallApi("get", apiRoute, params);
  };
  GetUserPermissionsCombined = async () => {
    let apiRoute = Constants.userPermissionsRoot + "GetUserPermissionsCombined";
    let params = { application: process.env.REACT_APP_ID };
    return this.CallApi("get", apiRoute, params);
  };
  GetUserSettings = async () => {
    let apiRoute = `${Constants.userSettingsRoot}UserSetting/${process.env.REACT_APP_ID}/${this.state.email}`;
    return this.CallApi("get", apiRoute);
  };
  // Get user settings per specific app
  GetUserAppSettings = async (app) => {
    let apiRoute = `${Constants.userSettingsRoot}UserSetting/${app}/${this.state.email}`;
    return this.CallApi("get", apiRoute);
  };
  GetOnlineAppToken = async () => {
    let apiRoute = process.env.REACT_APP_AZURE_ONLINE_APP_TOKEN_API;
    return this.CallApi("get", apiRoute);
  };
  // Admin
  GetIsUserInNexus = async () => {
    let apiRoute = Constants.graphApiRoot + "Client/IsUserInDatabase";
    let id = this.state.email;
    let params = { id };
    return this.CallApi("get", apiRoute, params);
  };
  GetPendingUsersCount = async () => {
    let apiRoute = Constants.graphApiRoot + "Client/GetPendingUsersCount";
    return this.CallApi("get", apiRoute);
  };

  // Post
  PostUserSettings = async (app, customData) => {
    let apiRoute = Constants.userSettingsRoot + "UserSetting";
    let applicationName = app === "global" ? app : process.env.REACT_APP_ID;
    let username = this.state.email;
    let params = { applicationName, username, customData };
    return this.CallApi("post", apiRoute, params);
  };

  // ----------
  // Components
  // ----------
  GetAccountListAdvanced = async (
    Firm,
    Office,
    SalesCode,
    Account,
    AccountName,
    Range,
    Sort,
    filterActive
  ) => {
    let params = {
      Firm,
      Office,
      SalesCode,
      Account,
      AccountName,
      Range,
      Sort,
      filterActive,
    };
    let apiRoute = Constants.Domain + "/api/accountsearch/api/AccountSearch";
    return this.CallApi("get", apiRoute, params);
  };
  GetAccountList = async (params) => {
    let apiRoute = `${process.env.REACT_APP_ACCOUNT_SEARCH}ActivityDay`;
    return this.CallApi("post", apiRoute, params);
  };
  IsValidListOfOfficeAccounts = async (params) => {
    let apiRoute = `${process.env.REACT_APP_ACCOUNT_SEARCH}Multiple`;
    return this.CallApi("post", apiRoute, params);
  };
  CallApiSkipErrorHandling;

  // ----------
  // CustomerCenter
  // ----------
  GetBackOffice = async () => {
    let apiRoute = Constants.apiCustomerCenterRoot + "BackOffice";
    return this.CallApi("get", apiRoute);
  };
  GetBackOfficeFile = async (fileUrl) => {
    let apiRoute = Constants.apiCustomerCenterRoot + "BackOffice/DownloadFile";
    let params = { fileUrl };
    return this.CallApiGetAll("getBlob", apiRoute, params);
  };
  GetAdmisNotice = async () => {
    let apiRoute = Constants.apiCustomerCenterRoot + "AdmisNotice";
    return this.CallApi("get", apiRoute);
  };
  GetAdmisNoticeFile = async (fileUrl) => {
    let apiRoute = Constants.apiCustomerCenterRoot + "AdmisNotice/DownloadFile";
    let params = { fileUrl };
    return this.CallApiGetAll("getBlob", apiRoute, params);
  };
  GetAccountInfo = async (Office, Account) => {
    let apiRoute = Constants.apiCustomerCenterRoot + "Account";
    let params = { Office, Account };
    return this.CallApi("get", apiRoute, params);
  };
  GetAccountInfoChanges = async (Office, Account) => {
    let apiRoute = Constants.apiCustomerCenterRoot + "Account/Requests";
    let params = { Office, Account };
    return this.CallApi("get", apiRoute, params);
  };
  PostAccountInfo = async (Office, Account, Attribute, Value) => {
    let apiRoute = Constants.apiCustomerCenterRoot + "Account";
    let params = { Office, Account, Attribute, Value };
    return this.CallApi("post", apiRoute, params);
  };
  DeleteAccountInfo = async (RequestId) => {
    let apiRoute = Constants.apiCustomerCenterRoot + "Account";
    let params = { RequestId };
    return this.CallApi("delete", apiRoute, params);
  };
  GetProfileInfo = async (Office, Account) => {
    let apiRoute = Constants.apiCustomerCenterRoot + "User/Profile";
    let params = { Office, Account };
    return this.CallApi("get", apiRoute, params);
  };
  GetProfileInfoChanges = async (Office, Account) => {
    let apiRoute = Constants.apiCustomerCenterRoot + "User/Profile/Requests";
    let params = { Office, Account };
    return this.CallApi("get", apiRoute, params);
  };
  DeleteProfileInfo = async (RequestId) => {
    let apiRoute = Constants.apiCustomerCenterRoot + "User/Profile";
    let params = { RequestId };
    return this.CallApi("delete", apiRoute, params);
  };
  GetBankingInfo = async (Office, Account) => {
    let apiRoute = Constants.apiCustomerCenterRoot + "Banking";
    let params = { Office, Account };
    return this.CallApi("get", apiRoute, params);
  };
  GetBankingInfoChanges = async (Office, Account) => {
    let apiRoute = Constants.apiCustomerCenterRoot + "Banking/Requests";
    let params = { Office, Account };
    return this.CallApi("get", apiRoute, params);
  };
  DeleteBankingInfo = async (RequestId) => {
    let apiRoute = Constants.apiCustomerCenterRoot + "Banking";
    let params = { RequestId };
    return this.CallApi("delete", apiRoute, params);
  };
  DeleteBankingInstruction = async (Office, Account, InstructionId) => {
    let apiRoute = Constants.apiCustomerCenterRoot + "Banking/Instructions";
    let params = { Office, Account, InstructionId };
    return this.CallApi("delete", apiRoute, params);
  };

  // ----------
  // Quotes
  // ----------
  GetQuotes = async () => {
    let apiRoute = Constants.apiQuotes + "Quots/GetTickets";
    return this.CallApi("get", apiRoute);
  };
  GetAccountCodes = async (code) => {
    let apiRoute = Constants.apiStaticData + "Account/GetAccountListings";
    let params = { code };
    return this.CallApi("get", apiRoute, params);
  };
  GetCurrencyListing = async (code) => {
    let apiRoute = Constants.apiStaticData + "Iso/GetCurrencyListings";
    let params = { code };
    return this.CallApi("get", apiRoute, params);
  };
  VerifyQuotes = async (params) => {
    let apiRoute = Constants.apiQuotes + "Quots/ValidateTicket";
    return this.CallApi("post", apiRoute, params);
  };
  PostQuotes = async (type, data) => {
    let path = type === "edit" ? "Quots/UpdateTicket" : "Quots/CreateTicket";
    let apiRoute = Constants.apiQuotes + path;
    return this.CallApi("post", apiRoute, data);
  };
  GetExchangeListings = async (code) => {
    let apiRoute = Constants.apiStaticData + "Exchange/GetExchangeListings";
    let params = { code };
    return this.CallApi("get", apiRoute, params);
  };
  GetProductListings = async (code) => {
    let apiRoute = Constants.apiStaticData + "Product/GetProductListings";
    let params = { code };
    return this.CallApi("get", apiRoute, params);
  };

  // ----------
  // Statements
  // ----------
  GetStatement = async (type, Office, Account, date) => {
    let params = { date, Office, Account };
    let apiRoute = this.apiRouteBuilderStatements(type);
    return this.CallApi("get", apiRoute, params);
  };
  GetStatementDateList = async (type, Office, Account) => {
    let apiRoute = this.apiRouteBuilderStatementDates(type);
    let params = { Office, Account };
    return this.CallApi("get", apiRoute, params);
  };
  // ----------
  // Reports
  // ----------
  GetLogiSecureKey = (params) => {
    let apiRoute = `${process.env.REACT_APP_API_SECURE_KEY}GetLogiSecureKey?${params}`;
    return this.CallApi("get", apiRoute);
  };
  getEquityFile = async (params) => {
    let apiRoute = Constants.apiStatementsRoot + Constants.apiEquitiesforAll;
    return this.CallApiGetAll("getBlob", apiRoute, params);
  };
  getEquity = async (params) => {
    let apiRoute = Constants.apiStatementsRoot + Constants.apiEquities;
    return this.CallApi("get", apiRoute, params);
  };

  getDailyStatementAll = async (params) => {
    let apiRoute =
      Constants.apiStatementsRoot + Constants.apiDailyStatementsForAll;
    return this.CallApi("get", apiRoute, params);
  };
  getMonthlyStatementAll = async (params) => {
    let apiRoute =
      Constants.apiStatementsRoot + Constants.apiMonthlyStatementsForAll;
    return this.CallApi("get", apiRoute, params);
  };

  // ----------
  // Landing
  // ----------
  // Post method but it's meant to retrieve LvLiq values
  PostAccountLVValues = async (AccountList) => {
    let apiRoute = Constants.apiAccountSnapshotRoot + "MoneySummary";
    let params = AccountList;
    return this.CallApi("post", apiRoute, params);
  };

  // ----------
  // Secure Message Center
  // ----------
  GetSecureMessages = async (mailbox) => {
    let params = { mailbox };
    let apiRoute = Constants.messagesRoot;
    return this.CallApi("get", apiRoute, params);
  };
  GetMailboxList = async () => {
    let apiRoute = Constants.messagesRoot + "mailboxes";
    return this.CallApi("get", apiRoute);
  };
  GetTopics = async () => {
    let apiRoute = Constants.secMSGRoot + "api/Topics";
    return this.CallApi("get", apiRoute);
  };
  GetEmailList = async (emailSnippet) => {
    let params = { emailSnippet };
    let apiRoute = Constants.messagesEmailAutofill;
    return this.CallApi("get", apiRoute, params);
  };
  GetIsEmployee = async () => {
    let id = this.state.email;
    let permission = "SecuredMessageCenterEmployee";
    let params = { id, permission };
    let apiRoute = Constants.userPermissionsRoot + "CheckPermission";
    return this.CallApi("get", apiRoute, params);
  };
  GetQuickAttachments = async () => {
    const apiRoute = Constants.messagesRoot + "quickattachments";
    return this.CallApi("get", apiRoute);
  };
  PostMessage = async (params) => {
    let apiRoute = Constants.messagesRoot + "SendLiveEmail";
    return this.CallApi("post", apiRoute, params, "form");
  };

  PutReadSecureMessages = async (messageId, mailbox) => {
    let params = {
      messageId,
      mailbox,
    };
    let apiRoute = Constants.messagesRoot + "ReadMessage";
    return this.CallApi("put", apiRoute, params);
  };
  DeleteSecureMessages = async (messageId, mailbox) => {
    let params = {
      messageId,
      mailbox,
    };
    let apiRoute = Constants.messagesRoot;
    return this.CallApi("delete", apiRoute, params);
  };

  /// Account Snaphot
  GetAllPositionsDetails = async (Office, Account) => {
    let params = { Office, Account };
    let apiRoute = Constants.apiAccountSnapshotRoot + "Positions/GetDetail";
    return this.CallApi("get", apiRoute, params);
  };
  SendNewTransaction = (trInfo) => {
    switch (trInfo.transactionType) {
      case "Check In":
        return this._callCreateCheckIn(
          trInfo.office,
          trInfo.account,
          trInfo.amount,
          trInfo.bankID,
          trInfo.message,
          trInfo.checkNumber,
          trInfo.uniqueCallId
        );
      case "Check Out":
        return this._callCreateCheckOut(
          trInfo.office,
          trInfo.account,
          trInfo.amount,
          trInfo.fee,
          trInfo.bankID,
          trInfo.sendingOption,
          trInfo.chargeTo,
          trInfo.message,
          trInfo.uniqueCallId
        );
      case "ACH Pull":
        return this._callCreateACHPull(
          trInfo.office,
          trInfo.account,
          trInfo.amount,
          trInfo.fee,
          trInfo.bankID,
          trInfo.message,
          trInfo.uniqueCallId
        );
      case "ACH Push":
        return this._callCreateACHPush(
          trInfo.office,
          trInfo.account,
          trInfo.amount,
          trInfo.fee,
          trInfo.bankID,
          trInfo.message,
          trInfo.uniqueCallId
        );
      case "Wire In":
        return this._callCreateWireIn(
          trInfo.office,
          trInfo.account,
          trInfo.amount,
          trInfo.fee,
          trInfo.bankID,
          trInfo.message,
          trInfo.uniqueCallId
        );
      case "Wire Out":
        return this._callCreateWireOut(
          trInfo.office,
          trInfo.account,
          trInfo.amount,
          trInfo.fee,
          trInfo.bankID,
          trInfo.message,
          trInfo.uniqueCallId
        );
      default:
        throw new Error(`Wrong Transaction Type ${trInfo.transactionType}`);
    }
  };

  _callCreateWireOut = async (
    office,
    account,
    amount,
    fee,
    bankID,
    message,
    uniqueCallId
  ) => {
    const apiCashmanagerRoute =
      Constants.apiCashmanagerRoute + "WireOut/CreateWireOut";
    let paramsWireOut = {
      office: office,
      account: account,
      amount: parseFloat(amount),
      fee: parseFloat(fee),
      bankID: bankID,
      message: message,
      uniqueCallId: uniqueCallId,
    };
    return this.CallApiSkipErrorHandling(
      "post",
      apiCashmanagerRoute,
      paramsWireOut
    );
  };

  _callCreateWireIn = async (
    office,
    account,
    amount,
    fee,
    bankID,
    message,
    uniqueCallId
  ) => {
    const apiCashmanagerRoute =
      Constants.apiCashmanagerRoute + "WireIn/CreateWireIn";
    let paramsWireIn = {
      office: office,
      account: account,
      amount: parseFloat(amount),
      fee: parseFloat(fee),
      bankID: bankID,
      message: message,
      uniqueCallId: uniqueCallId,
    };
    return this.CallApiSkipErrorHandling(
      "post",
      apiCashmanagerRoute,
      paramsWireIn
    );
  };

  _callCreateACHPush = async (
    office,
    account,
    amount,
    fee,
    bankID,
    message,
    uniqueCallId
  ) => {
    const apiCashmanagerRoute =
      Constants.apiCashmanagerRoute + "AchPush/CreateACHPush";
    let paramsACHPush = {
      office: office,
      account: account,
      amount: parseFloat(amount),
      fee: parseFloat(fee),
      bankID: bankID,
      message: message,
      uniqueCallId: uniqueCallId,
    };
    return this.CallApiSkipErrorHandling(
      "post",
      apiCashmanagerRoute,
      paramsACHPush
    );
  };

  _callCreateACHPull = async (
    office,
    account,
    amount,
    fee,
    bankID,
    message,
    uniqueCallId
  ) => {
    const apiCashmanagerRoute =
      Constants.apiCashmanagerRoute + "AchPull/CreateACHPull";
    let paramsACHPull = {
      office: office,
      account: account,
      amount: parseFloat(amount),
      fee: parseFloat(fee),
      bankID: bankID,
      message: message,
      uniqueCallId: uniqueCallId,
    };
    return this.CallApiSkipErrorHandling(
      "post",
      apiCashmanagerRoute,
      paramsACHPull
    );
  };

  _callCreateCheckIn = async (
    office,
    account,
    amount,
    bankID,
    message,
    checkNumber,
    uniqueCallId
  ) => {
    const apiCashmanagerRoute =
      Constants.apiCashmanagerRoute + "CheckIn/CreateCheckIn";
    let paramsCheckIn = {
      office: office,
      account: account,
      amount: parseFloat(amount),
      bankID: bankID,
      message: message,
      checkNumber: checkNumber,
      uniqueCallId: uniqueCallId,
    };
    return this.CallApiSkipErrorHandling(
      "post",
      apiCashmanagerRoute,
      paramsCheckIn
    );
  };

  _callCreateCheckOut = async (
    office,
    account,
    amount,
    fee,
    bankID,
    sendingOption,
    chargeTo,
    message,
    uniqueCallId
  ) => {
    const apiCashmanagerRoute =
      Constants.apiCashmanagerRoute + "CheckOut/CreateCheckOut";
    let paramsCheckOut = {
      office: office,
      account: account,
      amount: parseFloat(amount),
      fee: parseFloat(fee),
      bankID: bankID,
      sendingOption: sendingOption,
      chargeTo: chargeTo,
      message: message,
      uniqueCallId: uniqueCallId,
    };
    return this.CallApiSkipErrorHandling(
      "post",
      apiCashmanagerRoute,
      paramsCheckOut
    );
  };

  ///////
  // ----------
  // BarChart
  // ----------
  GetBarchartSymbols = async (keyword) => {
    let apiRoute = `https://ondemand.websol.barchart.com/getSymbolLookUp.json?apikey=16aaa9c64ded530002edbb7eab615d26&keyword=${keyword}&fields=type`;
    return this.CallApiNoAuth(apiRoute);
  };
  GetFutureLeaders = async () => {
    let apiRoute =
      "https://ondemand.websol.barchart.com/getLeaders.json?apikey=16aaa9c64ded530002edbb7eab615d26&assetType=FUT&type=gainers";
    return this.CallApiNoAuth(apiRoute);
  };

  ///////
  // ----------
  // MAP
  // ----------

  /// Account Snaphot
  GetAccountSnapshot = async (params) => {
    let apiRoute = Constants.apiAccountSnapshotRoot + "AccountSnapshot";
    return this.CallApi("get", apiRoute, params);
  };
  GetPCSpanPositions = async (Office, Account) => {
    let apiRoute = Constants.apiPCRoot + "MarginAnalysis/GetSpanPosition";
    let params = { Office, Account };
    return this.CallApi("get", apiRoute, params);
  };
  GetPCSpanRisk = async (params) => {
    let apiRoute = Constants.apiPCRoot + "MarginAnalysis/GetSpanRisk";
    return this.CallApi("post", apiRoute, params);
  };
  GetProductList = async (params) => {
    let apiRoute = Constants.apiProductSearchRoot + "productsearch/" + params;
    return this.CallApi("get", apiRoute);
  };
  GetProductSearchPrice = async (params) => {
    let apiRoute = Constants.apiProductSearchRoot + "PriceSearch";
    return this.CallApi("get", apiRoute, params);
  };

  /////////
  // Helper Functions
  apiRouteBuilderStatements = (type) => {
    let route = Constants.apiStatementsRoot;
    if (type === "/Daily-Statements") {
      route += Constants.apiDailyStatements;
    } else if (type === "/Monthly-Statements") {
      route += Constants.apiMonthlyStatementsForAll;
    } else if (type === "/Equity-Run") {
      route += Constants.apiEquities;
    } else {
      route += Constants.apiYearlyStatements;
    }
    return route;
  };
  apiRouteBuilderStatementDates = (type) => {
    let route = Constants.apiStatementsRoot;
    if (type === "/Daily-Statements") {
      route += Constants.apiDailyStatementsDates;
    } else if (type === "/Monthly-Statements") {
      route += Constants.apiMonthlyStatementsDates;
    } else if (type === "/Equity-Run") {
      route += Constants.apiEquitiesDates;
    } else {
      route += Constants.apiYearlyStatementsDates;
    }
    return route;
  };

  // ResearchAdmin
  GetReportsAPI = async (params) => {
    let apiRoute = Constants.apiResearchRoot + "Admin/Reports";
    return this.CallApi("get", apiRoute, params);
  };
  GetAuthorsAPI = async (params) => {
    let apiRoute = Constants.apiResearchRoot + "Admin/Authors";
    return this.CallApi("get", apiRoute, params);
  };
  PostCreateReportAPI = async (params) => {
    let apiRoute = Constants.apiResearchRoot + "admin/createreport";
    return this.CallApi("post", apiRoute, params);
  };
  DeleteFileAPI = async () => {
    let params = this.props.guid;
    let apiRoute = Constants.apiResearchRoot + "admin/deletefile/";
    return this.CallApi("get", apiRoute, params);
  };

  /////////
  // HelloSign
  GetHelloSign = async (params) => {
    // Environment Variables
    let apiRoute = `${process.env.REACT_APP_FORMS_MANAGER}Singature/CreateEmbeddedSignatureLinkRequest`;
    return this.CallApi("post", apiRoute, params);
  };

  /////////
  // Research
  PostSubscriptions = async (params) => {
    let apiRoute = Constants.apiSubscriptions + "Subscribe";
    return this.CallApi("post", apiRoute, params);
  };

  GetResearchCatAPI = async (params) => {
    let apiRoute = Constants.apiArticles + "Category";
    let params2 = {
      Category: params,
      mostRecent: true,
    };
    return this.CallApi("get", apiRoute, params2);
  };

  GetResearchRelAPI = async (params) => {
    let apiRoute = Constants.apiArticles + "Related";
    return this.CallApi("get", apiRoute, params);
  };

  GetSearchAPI = async (params) => {
    let apiRoute = Constants.apiArticles + "Search";
    let params2 = {
      term: params,
    };
    return this.CallApi("get", apiRoute, params2);
  };

  GetSubscriptionsAPI = async (params) => {
    let apiRoute = Constants.apiSubscriptions + "RetrieveUserSubscriptions";
    return this.CallApi("get", apiRoute, params);
  };

  GetAllArticlesAPI = async () => {
    let apiRoute = Constants.apiArticles;
    let params = {
      mostRecent: true,
    };
    return this.CallApi("get", apiRoute, params);
  };

  GetPermissionsAPI = async () => {
    let apiRoute = Constants.apiResearchRoot + "admin/permissions";
    return this.CallApi("get", apiRoute);
  };
}
export default ApiClass;
