import React from "react";
import PropTypes from "prop-types";

export default function Footer(props) {
  let companyName =
    props.userType === "admisi.com"
      ? "ADM Investor Services International Limited."
      : "ADM Investor Services, Inc.";
  return (
    <p id="NexusFooter" style={{ textAlign: "center", margin: "10px 0 0 0" }}>
      &copy; {new Date().getFullYear()} {companyName}
    </p>
  );
}

Footer.propTypes = {
  userType: PropTypes.string.isRequired,
};
