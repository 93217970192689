export const LINKS = [
  {
    title: "Account Snapshot",
    clientId: "nexuswebaccountsnapshots",
    url: "/accountsnapshots/",
    icon: "account-snapshots",
  },
  {
    title: "Customer Center",
    clientId: "nexuswebcustomercenter",
    url: "/customercenter/",
    icon: "customer-center",
  },
  {
    title: "Portfolio Calculator",
    clientId: "nexuswebmap",
    url: "/portfoliocalculator/",
    icon: "map",
  },
  {
    title: "Report Creator",
    clientId: "nexuswebreportcreator",
    url: "/reportcreator/",
    icon: "report-creator",
  },
  {
    title: "Reports",
    clientId: "nexuswebreports",
    url: "/reports/",
    icon: "reports",
  },
  {
    title: "Research",
    clientId: "nexuswebresearch",
    url: "/research/",
    icon: "research",
  },
  {
    title: "Research Admin",
    clientId: "nexuswebresearchadmin",
    url: "/researchadmin/",
    icon: "research-admin",
  },
  {
    title: "Oak Web",
    clientId: "oakweb",
    url: "https://wwwdev.oaktrading.com/oakweb3/login.html",
    icon: "oak",
  },
  {
    title: "Admin",
    clientId: "nexuswebadmin",
    url: "/admin/",
    icon: "admin",
  },
  {
    title: "Secured Message Center",
    clientId: "nexuswebsecuredmessagecenter",
    url: "/securedmessagecenter/",
    icon: "secure-message",
  },
  {
    title: "Statements",
    clientId: "nexuswebstatements",
    url: "/statements/",
    icon: "statements",
  },
  {
    title: "Get More Apps...",
    clientId: "nexuswebcollectbrokerinfo",
    url: "/collectbrokerinfo/",
    icon: "customer-center",
  },
  {
    title: "Broker Admin",
    clientId: "nexuswebbrokeradmin",
    url: "/brokeradmin/",
    icon: "admin",
  },
  {
    title: "Offset Manager",
    clientId: "nexusweboffsetmanager",
    url: "/offsetmanager/",
    icon: "admin",
  },
  {
    title: "Cash Manager Admin",
    clientId: "nexuscashmanageradmin",
    url: "/cashmanageradmin/",
    icon: "admin",
  },
  {
    title: "Forms Manager",
    clientId: "nexusformsmanager",
    url: "/formsmanager/",
    icon: "admin",
  },
  {
    title: "Mobile Check",
    clientId: "nexusmobilecheck",
    url: "/mobilecheck/",
    icon: "admin",
  },
  {
    title: "Account Change Admin",
    clientId: "nexuswebtradetransfer",
    url: "/accountchangeadmin/",
    icon: "admin",
  },
];
