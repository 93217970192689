import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, {
  retries: 1,
  retryDelay: (retryCount) => {
    return retryCount * 2000;
  },
  shouldResetTimeout: true,
  retryCondition: (_error) => true, // retry no matter what
});

export function ApiCaller(type, token, apiRoute, apiParams, form) {
  const headers = {
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    Authorization: "Bearer " + token,
    "Content-Type": form !== "form" ? "text/json" : "multipart/form-data",
  };
  let params = apiParams ? apiParams : "";
  if (type === "getBlob")
    return axios.get(apiRoute, { headers, params, responseType: "blob" });
  else if (type === "get")
    return axios.get(apiRoute, { headers, params, timeout: 120000 });
  else if (type === "delete")
    return axios.delete(apiRoute, { headers, params });
  else if (type === "post") return axios.post(apiRoute, params, { headers });
  else if (type === "put") return axios.put(apiRoute, params, { headers });
  else if (type === "putQuery")
    return axios.put(apiRoute, {}, { headers, params });
  else return "error";
}
export function ApiCallerNoAuth(apiRoute) {
  return axios.get(apiRoute);
}
