import React, { Component } from "react";
import { AppWrapper } from "../components/sharedComponents"
class OnlineApp extends Component {
  constructor(props) {
    super(props);
    this.apiClass = props.ApiClass;
  }
  componentDidMount() {
    this.GetOnlineAppToken();
  }
  GetOnlineAppToken = async () => {
    let OnlineAppToken = await this.apiClass.GetOnlineAppToken();

    let location = +process.env.REACT_APP_IS_PROD
      ? window.location.origin + "/"
      : "https://devnexus.admis.com/";

    if (OnlineAppToken) {
      let link =
        process.env.REACT_APP_AZURE_ONLINE_APP_LINK +
        OnlineAppToken +
        "&From=" +
        location;
      document.location.replace(link);
    }
  };
  render() {
    return <div></div>;
  }
}

export default AppWrapper(OnlineApp);
