// import { LogLevel } from "@azure/msal-browser";

/**
 * Nexus Auth-Config
 */
const appDomain = window.location.origin + "/";
const appLink = appDomain + process.env.REACT_APP_PATH;
const authLink = `https://${process.env.REACT_APP_AZURE_SERVICE}.b2clogin.com/${process.env.REACT_APP_AZURE_SERVICE}.onmicrosoft.com/`;

export const b2cPolicies = {
  names: {
    //signUpSignIn: "B2C_1_SignInSignUp",
    //forgotPassword: "B2C_1_passwordreset",
    //MFA version
    signUpSignIn: "B2C_1_SignInSignUpMFA",
    forgotPassword: "B2C_1_resetpassword",
  },
  authorities: {
    signUpSignIn: {
      authority: `${authLink}B2C_1_SignInSignUpMFA`,
    },
    forgotPassword: {
      authority: `${authLink}B2C_1_resetpassword`,
    },
  },
  authorityDomain: `${process.env.REACT_APP_AZURE_SERVICE}.b2clogin.com`,
};
/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_APP_ID, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: appLink, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: appDomain + "logout/" + appLink, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    tokenRenewalOffsetSeconds: 3600,
    // loggerOptions: {
    //   loggerCallback: (level, message, containsPii) => {
    //     if (containsPii) {
    //       return;
    //     }
    //     switch (level) {
    //       case LogLevel.Error:
    //         console.error(message);
    //         return;
    //       case LogLevel.Info:
    //         console.info(message);
    //         return;
    //       case LogLevel.Verbose:
    //         console.debug(message);
    //         return;
    //       case LogLevel.Warning:
    //         console.warn(message);
    //         return;
    //     }
    //   },
    // },
  },
};
/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  scopes: [
    `https://${process.env.REACT_APP_AZURE_SERVICE}.onmicrosoft.com/${process.env.REACT_APP_AZURE_API_SCOPES}/user_impersonation`,
    "openid",
  ],
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [...protectedResources.scopes],
};
