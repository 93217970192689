const DefaultSettings = {
  Global: {
    AccountName: false,
    Sidebar: {
      Available: true,
      Visible: true,
    },
    Theme: "light",
    FavoriteAccounts: [],
    LayoutSettings: {
      AccountSnapshot: {
        PositionsTable: "",
        AccountSnapshotTiles: "",
        PositionsPageSize: 20,
      },
    },
    LastUsedSettings: {
      SidebarSearch: {
        Office: "",
        Account: "",
        SalesCode: "",
        AccountName: "",
        AccountSimpleSearch: "",
        isAdvancedSearch: false,
      },

      SelectedAccount: "",
      Currency: "",
    },
  },
  Local: {
    nexuswebmap: {
      PageType: 0,
      FavoriteProducts: [],
    },
    nexusweblanding: {
      Symbol: { Code: null, Name: null },
    },
    nexuswebresearch: {
      Favorites: [],
    },
    nexuswebresearchadmin: {
      Favorites: [],
      FavoriteReports: [],
      FavoriteAuthors: [],
    },
    nexuswebsecuredmessagecenter: {
      Drafts: [],
    },
  },
};
export { DefaultSettings };
