import React, { useState } from "react";
import classNames from "classnames";
import { b2cGetAccount } from "../../../components/sharedComponents/auth-wrapper";
import HelloSign from "hellosign-embedded";
import APIGetHelloSign from "../../../services/APIGetHelloSign";

import "./agreementForm.style.css";

const AgreementForm = ({
  onClickPrev,
  onClickNext,
  apiClass,
  setIsAgreementSigned,
  isAgreementSigned,
  accountList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const apiGetHelloSign = new APIGetHelloSign(apiClass);

  const getDocument = async () => {
    //TODO: Make sure user didnt signed the form
    let userAccountsString = "";
    accountList?.forEach((el) => {
      userAccountsString += `${el.value},`;
    });
    setIsLoading(true);
    const email = b2cGetAccount().username;
    const name = b2cGetAccount().name;
    let params = {
      // IsTestMode: true, //this is test mode for testing locally
      sendRequestOverEmail: false,
      showUrlToCustomer: true,
      forms: [
        {
          formName:
            "Website Access Agreement - Registration w Emailed Statements",
          templateId: "92e72b26f2cb7d5a003452fb6f582e3102722da0",
          signers: [
            {
              role: "Customer",
              email: email,
              name: name,
              sendOverEmail: false,
            },
          ],
          formFieldsValues: [
            {
              fieldId: "name",
              fieldValue: name,
              isRequired: true,
            },
            {
              fieldId: "email",
              fieldValue: email,
              isRequired: true,
            },
            {
              fieldId: "accountList",
              fieldValue: userAccountsString,
              isRequired: true,
            },
          ],
        },
      ],
    };
    let url = await apiGetHelloSign.getApi(params);
    setIsLoading(false);
    const client = new HelloSign();
    try {
      client.open(url, {
        clientId: process.env.REACT_APP_HS_CLIENT,
        testMode: true,
        skipDomainVerification: true,
        whiteLabeling: {
          header_background_color: "#FFFFFF",
          text_color2: "#000000",
        },
      });
      client.on("sign", () => {
        setIsAgreementSigned(true);
      });
    } catch (err) {
      console.log("error ", err);
    }
  };

  const renderButtonText = () => {
    if (isLoading) {
      return "loading...";
    } else if (isAgreementSigned) {
      return "Signed";
    } else {
      return "Open the agreement";
    }
  };

  return (
    <div className='agreement-box'>
      <div className='agreement__main'>
        <span>
          {isAgreementSigned
            ? "Please proceed to next step"
            : "Please sign the agreement form:"}
        </span>
        <button
          className='agreement__btn--open'
          onClick={getDocument}
          disabled={isAgreementSigned}>
          {renderButtonText()}
        </button>
      </div>
      <div className='agreement__btn-group'>
        <button className='agreement__btn--prev' onClick={onClickPrev}>
          Prev
        </button>
        <button
          className={classNames({
            "agreement__btn--submit": isAgreementSigned,
            "agreement__btn--disabled": !isAgreementSigned,
          })}
          onClick={onClickNext}
          disabled={!isAgreementSigned}>
          Next
        </button>
      </div>
    </div>
  );
};

export default AgreementForm;
